@font-face {
  font-family: 'Helvetic Name';
  src: url("./fonts/HelveticaNeueUltraLight/HelveticaNeueUltraLight.woff") format("woff"),
  url("./fonts/HelveticaNeueUltraLight/HelveticaNeueUltraLight.woff2") format("woff2");
}

@font-face {
  font-family: 'HelveticaNeueThin';
  src: url("./fonts/HelveticaNeue-Thin/HelveticaNeue-Thin.woff") format("woff");
}

@font-face {
  font-family: 'HelveticaThin';
  src: url("./fonts/HelveticaThin/HelveticaThin.woff") format("woff");
}

@font-face {
  font-family: 'Roboto Light';
  src: url("./fonts/RobotoLight/RobotoLight.woff") format("woff"),
  url("./fonts/RobotoLight/RobotoLight.woff2") format("woff2");
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

.swiper-container {
  height: 100%;
  width: 100%;
  margin: 0px auto;
  padding: 5px 0;
  box-sizing: border-box;
  z-index: 0;
}

.swiper-pagination-bullet {
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullet-active {
  background: #00BCD4;
}

.swiper-pagination-bullets {
  bottom: 0px;
  position: initial;
}

.swiper-wrapper {
  height: calc(100% - 19px);
  align-items: center;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  font-family: 'Roboto', sans-serif;
}

input::-ms-clear, input::-ms-reveal { display: none; }

button[type="button"]:disabled {
  cursor: not-allowed !important;
}

textarea {
  overflow: auto;
}
